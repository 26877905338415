import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { DesignComponent } from "./layout/design/design.component";

//added on 4/20/2022
import { LoginComponent } from './layout/login/login.component';
import { AuthGuard } from './_helpers/auth.gaurd';
//added on 4/21/2022
// import {WorkQueueComponent} from './components/work-queue/work-queue.component';
import {QueryComponent} from './components/query/query.component';
// import {AnalyticsComponent} from './components/analytics/analytics.component';
// import {AdminTaskComponent} from './components/admin-task/admin-task.component';

//6/3/2022
import { PagenotfoundComponent } from './components/errors/pagenotfound/pagenotfound.component';


const routes: Routes = [
    //{path: "",canActivate: [AuthGuard],component: DesignComponent},
    { 
      path: "", canActivate: [AuthGuard],component: DesignComponent
      ,children: 
      [{path: "", canActivate: [AuthGuard],component: DashboardComponent},
      // {path: "home", canActivate: [AuthGuard],component: DashboardComponent},
      // {path: "work-queue", canActivate: [AuthGuard],component: WorkQueueComponent},
      {path: "query", canActivate: [AuthGuard],component: QueryComponent},
        {
          path: "home",canActivate: [AuthGuard],
          loadChildren: () =>
            import("./components/dashboard/dashboard.module").then(
              (m) => m.DashboardModule
            ),
      },
      {
          path: "work-queue",canActivate: [AuthGuard],
          loadChildren: () =>
            import("./components/work-queue/work-queue.module").then(
              (m) => m.WorkQueueModule
            ),
        },
      {
          path: "query",canActivate: [AuthGuard],
          loadChildren: () =>
            import("./components/query/query.module").then(
              (m) => m.QueryModule
            ),
        },
      {
          path: "analytics",canActivate: [AuthGuard],
          loadChildren: () =>
            import("./components/analytics/analytics.module").then(
              (m) => m.AnalyticsModule
            ),
      },
      {
          path: "admin-task",canActivate: [AuthGuard],
          loadChildren: () =>
            import("./components/admin-task/admin-task.module").then(
              (m) => m.AdminTaskModule
            ),
      },
      ]
   },
    
    //added on 4/20/0222
    { path: 'login', component: LoginComponent },

   //added on 6/3/2022
    //Wild Card Route for 404 request
    { path: '**', pathMatch: 'full', 
    component: PagenotfoundComponent },


];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
