import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { Menu } from "src/app/mocks/menu";
// import { userRole } from "src/app/mocks/role";
// import { securityMock } from "src/app/mocks/securityMocks";
import { AppService } from "src/app/app.service";
// import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
// import { RouterLink } from "@angular/router";

//6/9/2022
import {LogData} from 'src/app/models/log-data';

@Injectable({
  providedIn: "root",
})
export class DataService {
  sideNavFlag = new BehaviorSubject(true);
  isLogin = new BehaviorSubject(true);
  pageTitle = new BehaviorSubject("");
  navMenu = new Subject();

  constructor(private appservice: AppService) {
    // console.log('data.service constr called');
  }
  setnavMenu() {
    this.navMenu.next();
  }
  getNavMenu(): Observable<any> {
    return this.navMenu.asObservable();
  }
  setSidenavInfo(sidenavToggleStatus) {
    this.sideNavFlag.next(sidenavToggleStatus);
    // this.isSidenavOpen = sidenavToggleStatus;
  }
  getSidenavInfo() {
    // console.log('new code::data.service getSidenavInfo() called...');
    return this.sideNavFlag;
  }

  setPageTitle(title) {
    this.pageTitle.next(title);
  }
  getPageTitle() {
    return this.pageTitle;
  }

  logout(data) {
    //console.log('data.service isLogin set to ');console.log(data);
    localStorage.setItem("isLogin", data)
    this.isLogin.next(data);
  }
  loginAPI(data) {
    this.isLogin.next(data);
  }

  logDataAPI(payload: LogData): Observable<any>
  {
    return this.appservice.logDataAPI(payload);
  }
  errorGenerator():Observable<any>{
    return this.appservice.errorGenerator();
  }
  getLoginStatus() {  

    if(localStorage.getItem("isLogin") != null && localStorage.getItem("isLogin") == "true") {
      this.isLogin.next(false);
    } else {
      this.isLogin.next(true);
    }
    // console.log('data.service getLoginStatus() called with isLogin =');
    // console.log(this.isLogin);
    // console.log('data.service getLoginStatus() called with localStorage.getItem(isLogin) =');
    // console.log(localStorage.getItem("isLogin"));
    return this.isLogin;
  }

  getMenuPermission() {
    var userInfo = JSON.parse(localStorage.getItem("userInfo"));
    // console.log('data.service getMenuPermission() userInfo');console.log(userInfo);
    let role: String = '', roleid: Number=0;

      if (userInfo && userInfo.role && String(userInfo.role) == 'BISCOURTSERVICES_ADMIN')
      {
        role='Admin';
        roleid=1;
      }
      else if (userInfo && userInfo.role && String(userInfo.role) == 'BISCOURTSERVICES_ANALYST')
      {
        role='Analyst';
        roleid=2;
      }
      else if (userInfo && userInfo.role && String(userInfo.role) == 'BISCOURTSERVICES_READONLY')
      {
        role = 'ReadOnly';
        roleid=3;
      }
      else
      {
        role='ReadOnly';
        roleid=3;
      }
  
    const menuPermission = JSON.parse(localStorage.getItem("menu"));
    var RoutePermission = menuPermission;

    // console.log('data.service menuPermission = ');console.log(menuPermission);
    // console.log('data.service RoutePermission = ');console.log(RoutePermission);

    if(menuPermission)
    {
        RoutePermission = menuPermission.filter(
        (item) => item.roleid == roleid
      );
      return RoutePermission[0]["page"];
    } 
    else
    {
      
    return [];
    }


  }

  getMenu() {
    return Menu;
  }

  getRoutingsecurity(user): any {
    return this.appservice.getRoutingSecurity(user);
  }//end

  getPageRoutingsecurity(): any {
    return this.appservice.getPageRoutingSecurity();
  }//end

  getRole(): any {
    return this.appservice.getRole();
  }

  userLogin(payload): any {
    return this.appservice.userLogin(payload);
  }//end of fx

}
