export class Constants {
    static readonly baseURL_Prod = 'https://lnprovider.lexisnexisrisk.com/api/';
    //static readonly baseURL_QA = 'https://lnproviderdataqa.lexisnexisrisk.com/api/';

    static readonly baseURL = Constants.baseURL_Prod;//current URL
    //authentication API endpoints
    static readonly loginURL = Constants.baseURL + "user/loginapi";
    static readonly logoutURL = Constants.baseURL + "user/logout";
    static readonly apiTestURL = Constants.baseURL + "test";

}