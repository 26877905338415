import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor,HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError,retry } from 'rxjs/operators';

import { AuthenticationService } from '../_services/authentication.service';
import { OnDestroy } from '@angular/core';

import {LogData} from 'src/app/models/log-data';
import { DataService } from "src/app/core/shared/data.service";
import { Subscription } from 'rxjs';
import { notificationMessage } from "src/app/mocks/nofification.message";
import { ToastrService } from "ngx-toastr";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    //added onb 6/13/2022
    logDataAPISubscription: Subscription;
    logOutSubscription: Subscription;

    constructor(
        private authenticationService: AuthenticationService
        //added 6/2/2022
        //private logService: LoggerService
                //6/9/2022
                //,private logservice: LogService
                ,private DataService: DataService
                ,private toastr: ToastrService

        ) { 

        }//end of constr

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //console.log('jwt.error.interceptor intercepted request = ');console.log(request);
        return next.handle(request).pipe(
            
                    //6/16/2022
                    //retry 1
                        retry(1),
                //old begin
                catchError(err => {
                    //console.log('jwt.error.interceptor err = ');console.log(err);
                    //  console.log('jwt.error.interceptor err.status = ');console.log(err.status);

                    var error:any;
                    var errorDisplay:any;

                    if(err.error && err.error.responseMsg) 
                    {
                        error = err.message+';details:'+err.error.responseMsg;
                        errorDisplay = err.error.responseMsg;
                    }
                    else
                    {
                        error = err.message;
                        errorDisplay = 'Unknown error was encountered! Check the database logs for details!';
                    }

                    var errorType = '';
                    if(err.statusText)
                    errorType = err.statusText;
                    //added on 6/13/2022
                    var userinfo = JSON.parse(localStorage.getItem("userInfo"));
                    let username = "";
                
                    //assign values
                    if(userinfo)
                    {
                        username = userinfo.unique_name;
                    }
                            

                        //handle errors
                    if (err.status === 401) {
                        console.log('user logged out due to authentication error; details:',error);
                    
                        var logData:LogData = {  Type: errorType,
                        Logtext: username +' has logged out successfully;'+String(error),
                        UserName: username};


                        // auto logout if 401 response returned from api
                        var sessionNow = "";
                        this.authenticationService.newUserInfoSession = JSON.parse(localStorage.getItem("UserInfoSession"));
                        if(this.authenticationService.newUserInfoSession && this.authenticationService.newUserInfoSession.session)
                        {
                          sessionNow = this.authenticationService.newUserInfoSession.session;
                        }

                        this.DataService.logDataAPI(logData).subscribe(data=>{
                            this.logOutSubscription = this.authenticationService.logout(sessionNow).subscribe(stuff=>{});
                          },err=>{
                            this.logOutSubscription = this.authenticationService.logout(sessionNow).subscribe(stuff=>{});
                          });

                          localStorage.setItem('reloaded','0');

                          localStorage.removeItem('menu');
                          localStorage.removeItem('userInfo');
                          localStorage.removeItem('isLogin');
                          localStorage.removeItem('token');
                          //this.jwtService.destroyToken();
                          this.authenticationService.currentUser.next(null);
                  
                          localStorage.setItem("UserInfoSessionOld", sessionNow);

                        //delay 1 second then reload
                        this.delayProcessing(1000).then(data =>{
                            location.reload();
                        });
                        
                    }//end of if
                    else if(err.status ===0)
                    {
                        console.log('connection refused: api is unvailable');//console.log(err.error);
                        this.toastr.error(
                            'Connection to API Server Refused! Server is likely unavailable.',
                            notificationMessage.ERROR
                          );
                    }
                    else if(err.status === 400)
                    {
                        console.log('Bad Request error encountered! Check database logs for details!')
                        this.toastr.error(
                            errorDisplay,
                            notificationMessage.ERROR
                          );
                    }
                    else if(err.status === 404)
                    {
                        console.log('API route not found! Check database logs for details!')
                        this.toastr.error(
                            error,
                            notificationMessage.ERROR
                          );
                    }
                    else if(err.status === 502)
                    {
                        console.log('Connection to API Server Refused! Server is likely unavailable.!')
                        this.toastr.error(
                            'Connection to API Server Refused - API Server is unreachable!',
                            notificationMessage.ERROR
                          );
                    }
                    else if(err.status >=500)
                    {
                        console.log('server error was encountered');//console.log(err.error);
    
                    }
                    else
                    {
                        console.log('server error was encountered')

                    }
                    

                    //return throwError(error);
                    return throwError('api error');
                }))
            //old end


    }//end of fx

    async delayProcessing(delayAmount: number) {
        return new Promise(resolve => setTimeout(resolve, delayAmount));
    }//end

    private handleErrors(error: any): 
    //Observable<any> 
    any
    {
        let errors: string[] = [];
        let msg: string = "";
        
        msg = error;
        // msg = "Status: " + error.status;
        // msg += " - Status Text: " + error.statusText;
        // if (error.json()) {
        //     msg += " - Exception Message: " + error.json().exceptionMessage;
        // }
        errors.push(msg);
        
        console.error('An error occurred', errors);
        return (errors);
    }//end of fx
}
