import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { HttpParams } from "@angular/common/http";
import { HttpRequest } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable, of } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";
import { serverResponse } from "src/app/models/server-response";
import { controlTable } from "./mocks/controlTable";
import { element } from "protractor";

//added on 4/21/2022
import { Constants } from './_config/constants';
//added on 5/5/2022
import {QueryModel} from './components/query/query-model';
import {FilterModel} from './components/query/query-filter-model';
import {QueryModule} from './components/query/query.module';
import { map } from 'rxjs/operators';

//added on 6/2/2022
import { throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

//added on 6/8/2022
// import 'rxjs/add/operator/map';
// import 'rxjs/add/operator/catch';
// import 'rxjs/add/observable/throw';

//6/9/2022
import {LogData} from './models/log-data';
import {WorkQueueModel} from 'src/app/models/work-queue-model';
import { FilterModule } from "ng2-smart-table/lib/components/filter/filter.module";
import {StateItem} from './models/state-item-model'

@Injectable({
  providedIn: "root",
})
export class AppService {
  private jsonHeaders = {
    accept: "application/json",
  };

  private xmlHeaders = {
    accept: "text/xml",
  };

  queryModel: QueryModel;

  //6/2/2022
  errorMsg: string;
  //6/9/2022
  logData: LogData;

  constructor(private http: HttpClient) { }

 
  getRoutingSecurity(user): Observable<serverResponse> {
    let params = new HttpParams();
    params.append("userName", user);

    var userinfo = JSON.parse(localStorage.getItem("userInfo"));;
    let username = "";

    //assign values
    if(userinfo)
    {
      username = userinfo.unique_name;
    }

    return this.http.get<serverResponse>(
      Constants.baseURL + `Security/PageAccessForRoles?userName=${username}`,
      { headers: this.jsonHeaders }
    );
  }//end

  getPageRoutingSecurity(): Observable<serverResponse> {
    // let params = new HttpParams();
    // params.append("userName", user);

    //var userinfo = JSON.parse(localStorage.getItem("userInfo"));;
    let username = "system_setup";

    // //assign values
    // if(userinfo)
    // {
    //   username = userinfo.unique_name;
    // }

    return this.http.get<serverResponse>(
      Constants.baseURL + `Security/PageAccessForRoles?userName=${username}`,
      { headers: this.jsonHeaders }
    )

  }//end

  getRole(): Observable<serverResponse> {
    return this.http.get<serverResponse>(Constants.baseURL + `UserRole/Roles`, {
      headers: this.jsonHeaders,
    })

  }//end

  userLogin(payload: any): Observable<any> {
    const header = new HttpHeaders({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    });
    //console.log('app.service userLogin called with payload=',payload);
    let newPayLoad: any = JSON.stringify(payload);

    return this.http.post<any>(
      Constants.baseURL + `LoginAPI`,
      //payload
      newPayLoad,
      { headers: header }
    );
  }//end

  logDataAPI(payload: LogData): Observable<any> {
    //set payload info:
    var userinfo = JSON.parse(localStorage.getItem("userInfo"));;
    let username = "";

    //assign values
    if(userinfo)
    {
      username = userinfo.unique_name;
    }
        
    payload.UserName = username;

    let newPayLoad: any = JSON.stringify(payload);

    //console.log('app.service logDataAPI called with ');console.log(payload);
    return this.http.post<serverResponse>(
      Constants.baseURL + `log/LogData`,
      //payload
      newPayLoad
      , {
        headers: this.jsonHeaders,
      });
  }//end


  saveSecurity(payload): Observable<serverResponse> {
    //stringify payload
    let newPayLoad: any = JSON.stringify(payload);
    return this.http.post<serverResponse>(
      Constants.baseURL + `Security`,
      newPayLoad
    );
  }


  getControlTable(): Observable<serverResponse> {
    return this.http.get<serverResponse>(Constants.baseURL + `ControlTable`, {
      headers: this.jsonHeaders,
    });
    // return of(controlTable);
  }
  errorGenerator(): Observable<serverResponse> {
    return this.http.get<serverResponse>(Constants.baseURL + `InvalidRoute`, {
      headers: this.jsonHeaders,
    });
    // return of(controlTable);
  }
  saveControlTable(payload): Observable<serverResponse> {
    //stringify payload
    let newPayLoad: any = JSON.stringify(payload);
    return this.http.post<serverResponse>(Constants.baseURL + `ControlTable`, newPayLoad, {
      headers: this.jsonHeaders,
    });
    // return of(controlTable);
  }

  getCourtStatusField(): Observable<serverResponse> {
    return this.http.get<serverResponse>(Constants.baseURL + `PriorityAdmin/Fields`, {
      headers: this.jsonHeaders,
    });
  }
  getCourtStatusFieldValue(payload: any) {
    return this.http.get<serverResponse>(Constants.baseURL + `PriorityAdmin/Values?fieldName=${payload}`, {
      headers: this.jsonHeaders,
    });
  }
  getPriorityAdminSearchResult(payload: any[]): Observable<serverResponse> {
    return this.http.post<serverResponse>(Constants.baseURL + `PriorityAdmin/Search`, payload, {
      headers: this.jsonHeaders,
    });
  }
  getCourtPriorities() {
    return this.http.get<serverResponse>(Constants.baseURL + `PriorityAdmin/Priorities`, {
      headers: this.jsonHeaders,
    });
  }
  updatePriorities(payload: any[]) {
    //stringify payload
    let newPayLoad: any = JSON.stringify(payload);
    return this.http.post<serverResponse>(Constants.baseURL + `PriorityAdmin/UpdatePriority`, newPayLoad, {
      headers: this.jsonHeaders,
    });
  }
  
  getSourceSetting(): Observable<serverResponse> {
    
    return this.http.get<serverResponse>(Constants.baseURL + `SourceSetting/GetSourceSettingData`, {
      headers: this.jsonHeaders,
    });
  }
  saveSourceSetting(payload): Observable<serverResponse> {
    //stringify payload
    let newPayLoad: any = JSON.stringify(payload);
    return this.http.post<serverResponse>(Constants.baseURL + `SourceSetting/SaveSourceSettingData`, newPayLoad, {
      headers: this.jsonHeaders,
    });
  }


  getSelectTables() {
    return this.http.get<serverResponse>(Constants.baseURL + `Query/SelectTables`, {
      headers: this.jsonHeaders,
    });
  }
  getSelectFields(payload: any) {
    // console.log('app.service getSelectFields() payload = ',payload)
    return this.http.get<serverResponse>(Constants.baseURL + `Query/SelectFields?tableName=${payload}`, {
      headers: this.jsonHeaders,
    });
  }
  getWhereFields() {
    return this.http.get<serverResponse>(Constants.baseURL + `Query/WhereFields`, {
      headers: this.jsonHeaders,
    });
  }
  getWhereValues(payload: any) {
    return this.http.get<serverResponse>(Constants.baseURL + `Query/WhereValues?fieldName=${payload}`, {
      headers: this.jsonHeaders,
    });
  }
  runQuery(payload: any,pageNumber:Number=1,rowsPerPage:Number=100,rowsCount:Boolean=false) {
    //stringify payload
    let newPayLoad: any = JSON.stringify(payload);
    return this.http.post<serverResponse>(Constants.baseURL + `Query/RunQuery?pageNumber=${pageNumber}&rowsPerPage=${rowsPerPage}&rowsCount=${rowsCount}`, newPayLoad, {
      headers: this.jsonHeaders,
      observe: 'response'
    });
  }
  GetQueries_old() {
    return this.http.get<serverResponse>(Constants.baseURL + `Query/GetQueries`, {
      headers: this.jsonHeaders,
    });
  }//end

  GetQueries(): Observable<QueryModel[]> {
    let queryModels:QueryModel[] = [];

    return this.http.get<any>(Constants.baseURL + `Query/GetQueries`, {
      headers: this.jsonHeaders,
    }).pipe(
      
      
      map(response => 
      {
        //console.log('app.service GetQueries response = '); console.log(response);

        //console.log('response parse');console.log(JSON.parse(response))

        //let res = JSON.parse(response);
        let res = response.values;
        //console.log('app.service GetQueries res = '); console.log(res);
 

        res.forEach(item => {
          let filters:FilterModel[] = [];
          
          //console.log('app.service GetQueries item.criteria = '); console.log(item.criteria);
          item.criteria.forEach(element => {
            filters.push({
              Field: element.field,
              ConditionalOperator : element.conditionalOperator,
              Value : element.value,
              LogicalOperator : element.logicalOperator
            })
          });

          let thesefields:string[] = [];
          item.fields.forEach(element => {
            thesefields.push(element)
          });

          queryModels.push({
            QueryName:item.queryName,QueryType:item.queryType,UserName:item.userName,TableName:item.tableName,Fields:item.fields,Criteria:filters          
          }) ;
        });
 
      return queryModels;
      }
      )

      );;
  }//end



  SaveSharedQuery(data: any) {
    var userinfo = JSON.parse(localStorage.getItem("userInfo"));;
    let username = "";
    if(userinfo)
    {
      username = userinfo.unique_name;
    }
    data.UserName = username;

    //stringify payload
    let newPayLoad: any = JSON.stringify(data);
    // console.log('app.service SaveSharedQuery data = ');console.log(data);
    return this.http.post<serverResponse>(Constants.baseURL + `Query/SaveSharedQuery`, newPayLoad, {
      headers: this.jsonHeaders,
      observe: 'response'
    });
  }
  SaveMyQuery(data: any) {
    var userinfo = JSON.parse(localStorage.getItem("userInfo"));;
    let username = "";
    if(userinfo)
    {
      username = userinfo.unique_name;
    }
    data.UserName = username;
    //stringify payload
    let newPayLoad: any = JSON.stringify(data);
    // console.log('app.service saveMyQuery data = ');console.log(data);
    return this.http.post<serverResponse>(Constants.baseURL + `Query/SaveMyQuery`, newPayLoad, {
      headers: this.jsonHeaders,
      observe: 'response'
    });
  }//end


  GetQueryDetails(data: any): Observable<QueryModel> {

    //this.queryModel;
    let queryMod: QueryModel;

    var userinfo = JSON.parse(localStorage.getItem("userInfo"));;
    let username = "",
    queryName = "",
    dataType = "";

    //assign values
    if(userinfo)
    {
      username = userinfo.unique_name;
    }
    if(data.queryName) queryName = data.queryName;

    if(data.type) dataType = data.type;

    //username = "Demo";


    // console.log('app.service username = ');console.log(username);
    return this.http.get<any>(Constants.baseURL + `Query/GetQueryDetails?queryName=${queryName}&userName=${username}&type=${dataType}`
    , {
      headers: this.jsonHeaders,
    }
    )
    .pipe(map(response => 
      {
        //console.log('app.service GetQueryDetails response = ',response);
        //console.log('app.service GetQueryDetails response.values = ',response.values);
        //console.log(response);
        //let res = JSON.parse(response);
        let res = response.values;
        //res.forEach(item => {});
        let filters:FilterModel[] = [];
        res.criteria.forEach(element => {
          filters.push({
            Field: element.field,
            ConditionalOperator : element.conditionalOperator,
            Value : element.value,
            LogicalOperator : element.logicalOperator
          })
        });

        let thesefields:string[] = [];
        res.fields.forEach(element => {
          thesefields.push(element)
        });

        queryMod = {
          QueryName:res.queryName,QueryType:res.queryType,UserName:res.userName,TableName:res.tableName,Fields:res.fields,Criteria:filters
        };

        
      return queryMod;
      }));

  }//end
          

  GetAnalyticsData() {
    return this.http.get<serverResponse>(Constants.baseURL + `Analytics/GetAnalyticsData`, {
      headers: this.jsonHeaders,
    });
  }
  GetAnalyticsCourtStatusData() {
    return this.http.get<serverResponse>(Constants.baseURL + `Analytics/GetAnalyticsCourtStatusData`, {
      headers: this.jsonHeaders,
    });
  }
  
  GetWorkQueueData(data) {
    var userinfo = JSON.parse(localStorage.getItem("userInfo"));;
    let username = "";

    //assign values
    if(userinfo)
    {
      username = userinfo.unique_name;
    }
    return this.http.get<serverResponse>(Constants.baseURL + `WorkQueue/GetWorkQueueData?username=${username}`, {
      headers: this.jsonHeaders,
    }); 
  }//end
  //added on 6/30/2022
  GetWorkQueueDataPaginated(pageNumber:number,rowsPerPage:number) {
    var userinfo = JSON.parse(localStorage.getItem("userInfo"));;
    let username = "";

    //assign values
    if(userinfo)
    {
      username = userinfo.unique_name;
    }
    let pageNber = 1, rowsPP = 25;
    if(pageNumber && pageNumber>0) pageNber = pageNumber;
    if(rowsPerPage && rowsPerPage>0) rowsPP = rowsPerPage;

    return this.http.get<serverResponse>(Constants.baseURL + `WorkQueue/GetWorkQueueDataPaginated?username=${username}&pagenumber=${pageNber}&rowsperpage=${rowsPP}`, {
      headers: this.jsonHeaders,
    }); 
  }//end
  GetWorkQueueDataFiltered(data: WorkQueueModel) {
    var userinfo = JSON.parse(localStorage.getItem("userInfo"));;
    let username = "";

    //assign values
    if(userinfo)
    {
      username = userinfo.unique_name;
    }
    return this.http.post<serverResponse>(Constants.baseURL + `WorkQueue/GetWorkQueueDataFiltered?username=${username}`,data, {
      headers: this.jsonHeaders,
    }); 
  }//end

  GetWorkQueueDataTotal(data: Number) {
    //fix data is bad data is passed
    if(data>0){}
    else data = 25;
    return this.http.get<serverResponse>(Constants.baseURL + `WorkQueue/GetWorkQueueDataTotal?rowsperpage=${data}`, {
      headers: this.jsonHeaders,
    }); 
  }//end
  CheckIsLocked(payload: { id: any; userid: any; }) {
    var userinfo = JSON.parse(localStorage.getItem("userInfo"));;
    let username = "";

    //assign values
    if(userinfo)
    {
      username = userinfo.unique_name;
    }
 

    return this.http.get<serverResponse>(Constants.baseURL + `WorkQueue/CheckIsLockedOrNot?id=${payload.id}&username=${username}`, {
      headers: this.jsonHeaders,
    }); 
  }
  UncheckIsLocked(payload: { id: any; userid: any; }) {
    var userinfo = JSON.parse(localStorage.getItem("userInfo"));;
    let username = "";

    //assign values
    if(userinfo)
    {
      username = userinfo.unique_name;
    }
    return this.http.get<serverResponse>(Constants.baseURL + `WorkQueue/UncheckIsLockedData?id=${payload.id}&username=${username}`, {
      headers: this.jsonHeaders,
    }); 
  }
  GetWorkQueueDataAll(data: any) {
    var userinfo = JSON.parse(localStorage.getItem("userInfo"));;
    let username = "";

    //assign values
    if(userinfo)
    {
      username = userinfo.unique_name;
    }
    // console.log(data.ids,"++++")
    var allid = '';
    data.ids.forEach((e, i) => {
      if(i==0) {
        allid += 'ids='+e;
      } else {
        allid += '&ids='+e;
      }
    });
    
    return this.http.get<serverResponse>(Constants.baseURL + `WorkQueue/GetWorkQueueDataAll?username=${username}&${allid}`, {
      headers: this.jsonHeaders,
    });
  }//end

  SaveWorkQueueDataAll(data: any) {
    //stringify payload
    let newPayLoad: any = JSON.stringify(data);
    return this.http.post<serverResponse>(Constants.baseURL + `WorkQueue/SaveWorkQueueDataAll`, newPayLoad, {
      headers: this.jsonHeaders,
    }); 
  }//end

  GetTempWorkQueueDataAll(data: any) {
    var userinfo = JSON.parse(localStorage.getItem("userInfo"));
    let username = "";

    //assign values
    if(userinfo)
    {
      username = userinfo.unique_name;
    }
    return this.http.get<serverResponse>(Constants.baseURL + `WorkQueue/GetTempWorkQueueDataAll?username=${username}`, {
      headers: this.jsonHeaders,
    });
  }
  SaveTempWorkQueueDataAll(data: { userName: any; workQueueDataList: any[]; }) {
    var userinfo = JSON.parse(localStorage.getItem("userInfo"));;
    let username = "";

    //assign values
    if(userinfo)
    {
      username = userinfo.unique_name;
    }
    data.userName = username;
    //stringify payload
    let newPayLoad: any = JSON.stringify(data);
    return this.http.post<serverResponse>(Constants.baseURL + `WorkQueue/SaveTempWorkQueueDataAll`, newPayLoad, {
      headers: this.jsonHeaders,
    }); 
  }//end of fx

  GetStateData():Observable<StateItem[]> {
    let statedatalist:StateItem[] = [];

    return this.http.get<serverResponse>(Constants.baseURL + `WorkQueue/GetStateData`, {
      headers: this.jsonHeaders,
    })
    .pipe(
      
      
      map(response => 
      {
        //console.log('app.service GetQueries response = '); console.log(response);

        let res = response.responseData;
        //console.log('app.service GetQueries res = '); console.log(res);
        
        res.forEach(item => {

          statedatalist.push({
            StateName:item.stateName,Description :String(item.stateName)+' - '+String(item.stateDescription)        
          }) ;
        });
 
      return statedatalist;
      }
      )

      );
    
  }//end

    //handle errorrs
    //added on 6/8/2022

    
  
}
