
<div class="row">
  <div class="col-3">
    <div class="card shadow">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <ul class="queryList pl-2">
              <li class="queryListItem">
                <button type="button" class="btn btn-secondary" class="queryList-link" (click)="newquery()">New Query</button>
              </li>
              <li class="queryListItem">
                <button type="button" class="btn btn-secondary"
                  data-toggle="collapse"
                  href="#MyQueries"
                  aria-expanded="false"
                  aria-where="MyQueries"
                  class="queryList-link"
                  >My Queries <em class="fa fa-angle-down ml-2"></em
                ></button>
                <ul
                  class="submenu-list"
                  id="MyQueries"
                  *ngIf="myQueriesLists.length > 0"
                >
                  <li class="queryListItem" *ngFor="let list of myQueriesLists">
                    <a
                      class="queryList-link"
                      (click)="getQueryDetails(list, 'private')" style="cursor: pointer"
                      >{{ list.QueryName }}
                    </a>
                  </li>
                </ul>
              </li>
              <li class="queryListItem">
                <button type="button" class="btn btn-secondary"
                  data-toggle="collapse"
                  href="#SharedQueries"
                  aria-expanded="false"
                  class="queryList-link"
                  >Shared Queries <em class="fa fa-angle-down ml-2"></em
                ></button>
                <ul
                  class="submenu-list"
                  id="SharedQueries"
                  *ngIf="sharedQueriesLists.length > 0"
                >
                  <li
                    class="queryListItem"
                    *ngFor="let list of sharedQueriesLists"
                  >
                    <a
                      class="queryList-link"
                      (click)="getQueryDetails(list, 'shared')"
                      style="cursor: pointer"  
                      >{{ list.QueryName }}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="card">
      <div class="card-header">Select Fields</div>
      <div class="card-body">
        <p>(note: set from left to right)</p>   
        <div class="row mb-2">
          <div class="col-2"></div>
          <div class="col title">Table</div>
          <div class="col title">Field</div>
          <div class="col"></div>
        </div>
        <form [formGroup]="form">
          <div formArrayName="queryTable">
            <div
              class="row mb-2"
              *ngFor="let control of aliasQueryTable.controls; let i = index"
              [formGroupName]="i"
            >
              <div class="col-2">
                <em
                  class="text-success fa fa-plus-circle fa-2x mr-2"
                  aria-hidden="true"
                  (click)="addQueryTable()"
                ></em>
                <em
                  class="text-danger fa fa-minus-circle fa-2x"
                  aria-hidden="true"
                  (click)="removeQueryTable(i)"
                ></em>
              </div>
              <div class="col">
                <ng-select
                
                  [items]="table"
                  [searchable]="true"
                  bindLabel="name"
                  formControlName="table"
                  (change)="onchangeQueryTable($event, i)"
 
                >
                </ng-select>
              </div>
              <div class="col">
                <ng-select
                  [items]="field"
                  [searchable]="true"
                  bindLabel="name"
                  formControlName="field"

                >
                </ng-select>
              </div>
              <div class="col"></div>
            </div>

          </div>
        </form>
      </div>
    </div>

    <div class="card">
      <div class="card-header">Where</div>
      <div class="card-body">
        <p>(note: set from left to right) To cancel a loading query, click cancel button or refresh this page.</p> 
        <div class="row mb-2">
          <div class="col-2 px-1"></div>
          <div class="col title">And / Or</div>
          <div class="col title">Field</div>
          <div class="col title">Operator</div>
          <div class="col title">Value</div>
        </div>

        <form [formGroup]="form">
          <div formArrayName="queryCondition">
            <div
              class="row mb-2"
              *ngFor="
                let control of aliasQueryCondition.controls;
                let i = index
              "
              [formGroupName]="i"
            >
              <div class="col-2">
                <em
                  class="text-success fa fa-plus-circle fa-2x mr-2"
                  aria-hidden="true"
                  (click)="addQueryCondition()"
                ></em>
                <em
                  class="text-danger fa fa-minus-circle fa-2x"
                  aria-hidden="true"
                  (click)="removeQueryCondition(i)"
                ></em>
              </div>
              <div class="col">
                <div *ngIf="i != 0; else loggedOut">
                  <ng-select
                    [items]="condition"
                    [searchable]="true"
                    bindLabel="name"
                    formControlName="condition"
                  >
                  </ng-select>
                </div>

                <ng-template #loggedOut>
                  <input type="hidden" fornControlName="condition" />
                </ng-template>
              </div>
              <div class="col">
                <ng-select
                  [items]="whereFields"
                  [searchable]="true"
                  bindLabel="name"
                  formControlName="whereFields"
                  (change)="
                    onchangeWhereFields($event, i, '666666666644444444')
                  "
                >
                </ng-select>
              </div>
              <div class="col">
                <ng-select
                  [items]="operator"
                  [searchable]="true"
                  bindLabel="name"
                  formControlName="operator"
                  (change)="onOperatorSelect($event, i)"
                >
                </ng-select>
              </div>
              <div class="col">
                <div
                  *ngIf="
                    form?.controls['queryCondition']?.controls[i]?.controls
                      ?.operator?.value?.name == 'contains' ||   
                      form?.controls['queryCondition']?.controls[i]?.controls
                      ?.operator?.value?.name == 'like';
                    else valueChange
                  "
                >
                  <input type="text"  bindLabel="name" formControlName="value" (change)="onContainSelect($event,i,control)" (click)="onContainSelect($event,i,control)" />
                  <!-- <input type="text"  bindLabel="name" formControlName="value" (change)="onContainSelect($event,i,control)" (click)="onContainSelect($event,i,control)" (mouseover)="onContainSelect($event,i,control)"/> -->


                </div>

                <ng-template #valueChange>
                  <ng-select
                    [items]="value[i]"
                    [searchable]="true"
                    bindLabel="name"
                    formControlName="value"
                    (change)="onContainSelect($event,i,control)"
                  >
                  </ng-select>
                </ng-template>
              </div>
            </div>

          </div>
        </form>
      </div>
    </div>

    <div class="row mb-4" >
      <div class="col">
        <button class="btn btn-primary" (click)="dataBinding('runQuery')"
        [disabled]="progressBarFullTeamValue > 0 && progressBarFullTeamValue < 100"
        >
          Run Query
        </button>
        <button
          class="btn btn-primary mx-2"
          data-toggle="modal"
          data-target="#myQuery"
          [disabled]="btnMyQry || progressBarFullTeamValue < 100"
          *ngIf="userInfo.role == 'BISCOURTSERVICES_ADMIN' || userInfo.role == 'BISCOURTSERVICES_ANALYST'"
        >
          Save My Query
        </button>
        <button
          class="btn btn-primary"
          data-toggle="modal"
          data-target="#sharedQuery"
          [disabled]="btnSharedQry || progressBarFullTeamValue < 100"
          *ngIf="userInfo.role == 'BISCOURTSERVICES_ADMIN' || userInfo.role == 'BISCOURTSERVICES_ANALYST'"
        >
          Save Shared Query
        </button>
        <button
          class="btn btn-primary mx-2"
          (click)="exportCSV()"
          [disabled]="progressBarFullTeamValue < 100"
          *ngIf="userInfo.role == 'BISCOURTSERVICES_ADMIN' || userInfo.role == 'BISCOURTSERVICES_ANALYST'"
        >
          Export Excel/CSV
        </button>
        <button
          class="btn btn-primary"
          data-toggle="modal"
          [disabled]="btnSaveCopy || progressBarFullTeamValue < 100"
          data-target="#saveAsCopy"
          *ngIf="userInfo.role == 'BISCOURTSERVICES_ADMIN' || userInfo.role == 'BISCOURTSERVICES_ANALYST'"
        >
          Save as Copy
        </button>
        <button
        class="btn btn-primary mx-2"
        (click)="cancelQuery()"

        [disabled]="progressBarFullTeamValue == 0 || progressBarFullTeamValue == 100"
        *ngIf="userInfo.role == 'BISCOURTSERVICES_ADMIN' || userInfo.role == 'BISCOURTSERVICES_ANALYST'"
      >
        Cancel
      </button>
      </div>
    </div>
    <div class="card">
      <!-- <div class="card-header">Results from {{queryName}}</div> -->

      <div class="two-col"  style="display: flex;align-items: center;">
        <div class="col1">
          <label for="Summary1">{{progressBarFullTeamLabel}}</label>

          <!-- <form [formGroup]="pageForm">
            <span>Page </span>
            <input matInput (keyup.enter)="goToPage()" name="pageInput" formControlName="pageInput" step="1" [(ngModel)]="pageNumber">
            <span>Rows per Page (1-1000) </span>  
            <input matInput name="pageInput2" formControlName="pageInput2" step="1" [(ngModel)]="rowsPerPage">
            <button  class="closebtn" (click)="goToPage()">Go To Page</button>
            <span>(Hit ENTER key to get data for this page) </span>
                      
          </form> -->

        </div>
        <div class="col2">
          <!-- <button disabled  class="closebtn" (click)="prevPage()"><<</button>
          <button disabled  class="closebtn" (click)="nextPage()">>></button> -->
        </div>
      </div>


      <div class="card-header" *ngIf="queryName.length > 0; else resultsUnnamedQuery">Query data stats for {{queryName}}: {{queryRowsCountMessage}}</div>
      <ng-template #resultsUnnamedQuery>
        <div class="card-header" *ngIf="paResultData.length > 0; else resultsNotFound">Query data stats: {{queryRowsCountMessage}}</div>
      </ng-template>
      <ng-template #resultsNotFound>
        <div class="card-header">Please select at least one field for the query! Where clause is required for a valid query.</div>
      </ng-template>
      <div class="card-body" *ngIf="paResultData.length > 0; else elseSection">
        <ng2-smart-table
          [settings]="paResultSetting"
          [source]="paResultData"
          (userRowSelect)="onUserRowSelect($event)"
        ></ng2-smart-table>
        <!-- <ngb-pagination
          [pageSize]="25"
          [boundaryLinks]="true"
          (pageChange)="onPagination($event)"
        ></ngb-pagination> -->
      </div>
      <ng-template #elseSection>
        <!-- <p class="p-5 text-center">Click 'Run Query' to Run the above query once set.</p> -->
      </ng-template>
    </div>
  </div>
</div>

<div class="modal fade" id="myQuery" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">My Query</h4>
        <button type="button" #closebutton class="close" data-dismiss="modal">
          &times;
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label for="queryName">Query Name</label>
          <input type="text" class="form-control" [(ngModel)]="queryName" />
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          id="closeModal"
          (click)="dataBinding('myQuery')"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="sharedQuery" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Shared Query</h4>
        <button type="button" #closebutton class="close" data-dismiss="modal">
          &times;
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label for="queryName">Query Name</label>
          <input type="text" class="form-control" [(ngModel)]="queryName" />
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          id="closeModal"
          (click)="dataBinding('sharedQuery')"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="saveAsCopy" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Save as Copy</h4>
        <button type="button" #closebutton class="close" data-dismiss="modal">
          &times;
        </button>
      </div>
      <div class="modal-body">
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            [(ngModel)]="queryType"
            name="qryType"
            id="private"
            value="Private"
          />
          <label class="form-check-label" for="private">My Query</label>
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            [(ngModel)]="queryType"
            name="qryType"
            id="shared"
            value="Shared"
          />
          <label class="form-check-label" for="shared">Shared Query`</label>
        </div>
        <div class="form-group mt-3">
          <label for="queryName">Query Name</label>
          <input type="text" class="form-control" [(ngModel)]="queryName" />
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          id="closeModal"
          (click)="dataBinding('saveAsCopy')"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</div>
