import { Component, OnInit,OnDestroy,ChangeDetectionStrategy } from '@angular/core';
import { DataService } from "../../core/shared/data.service";
import { ActivatedRoute,Router,NavigationEnd } from '@angular/router';

//added on 04/20/2022
import { User } from '../../_models/user';
import { Subscription,timer,Observable } from 'rxjs';
import { AuthenticationService } from '../../_services/authentication.service';

//added 05/02/2022
import { Subject } from 'rxjs';
import { HostListener} from "@angular/core";
//added 6/15/2022
import {LogData} from 'src/app/models/log-data';
import { JwtService } from 'src/app/_services/jwt.service'
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-navbar-main',
  templateUrl: './navbar-main.component.html',
  styleUrls: ['./navbar-main.component.css']
  //session timer
  ,changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavbarMainComponent implements OnInit,OnDestroy {
  isSidenavOpen: boolean = true;
  pageTitle: string;
  userInfo: any = JSON.parse(localStorage.getItem("userInfo"));

  //added on 4/20/2022
  user:User = null;
  userSubscription:Subscription;
  login: Boolean = false;
  sidebar: Boolean = true;

  //user activity
  //added on 5/2/2022
  userActivity;
  userInactive: Subject<any> = new Subject();

  logDataAPISubscription: Subscription;
  logOutSubscription: Subscription;

  //session timer
  intervalId:any;
  timeSubscription:Subscription;

  //time remaining for the user session until timeout
  sessionTimeRemainingMinutes = 0;
  sessionTimeRemainingSeconds = 0;
  sessionTimeRemainingWholeSeconds = 0;
  sessionTimeRemainingSecondsVal = '0';
  sessionTimeOutWarningThreshold = 5;//5 minutes

  public $time: Observable<Date> = timer(0, 1000).pipe(map(() => new Date()));


      constructor(
        private DataService: DataService,
        private router:Router
        //,private activatedRoute:ActivatedRoute

        //added on 4/20/2022
        ,private authenticationService:AuthenticationService
        ,private jwtService: JwtService
      ) 
      { 
        //console.log('navbra-main constructor called ');
        //5/2/2022
        this.setTimeout();
        //5/24/2022
        this.userInactive.subscribe(() => {
          this.logout();
        });  

        //session
        this.setSessionRemainingTime();
      }//end of constr

      ngOnInit() {
        this.DataService.getPageTitle().subscribe(value => {
          this.pageTitle = value


          //added on 4/20/2022
          this.userSubscription = this.authenticationService.currentUser
          .subscribe((user) => {
            this.user = user;

            //console.log('user='); console.log(this.user);

            //added on Nov 1
            if (!user) {
              this.login = false;
            }
            else{
              this.login = true;

            }
            //added on Nov 1

            
          });
          //added on 5/24/2022
          //this.login = true;

          //console.log('navbar-main.components oninit called');

        })
          //session
          this.setSessionRemainingTime();
        
      }//end of fx

      timeChangeEvent()
      {
        this.setSessionRemainingTime();
      }
      
      toggleNavbar() {
        // console.log('nav bar toggle clicked.....')
        this.isSidenavOpen = !this.isSidenavOpen;
        this.DataService.setSidenavInfo(this.isSidenavOpen);
        this.sidebar = this.isSidenavOpen;
        // console.log('this.isSidenavOpen='+this.isSidenavOpen);

      }//end

      //added on 04/21/2022
      openNav() {
        document.getElementById("mySidebar").style.width = "250px";
        document.getElementById("main").style.marginLeft = "250px";
        //  console.log('open nav called')
      }
      
      /* Set the width of the sidebar to 0 (hide it) */
      closeNav() {
        document.getElementById("mySidebar").style.width = "0";
        document.getElementById("main").style.marginLeft= "0";
        // console.log('close nav called');
      }//end

      toggleSideBar() {
        ////console.log("toggled sidebar");
        this.sidebar = !this.sidebar;
        ////console.log(this.sidebar);
      }

      logout() {
            //log information before logout request
            var userinfo = JSON.parse(localStorage.getItem("userInfo"));
            //console.log('navbar-main.component logout() called ')
            let username = "";
            //assign values
            if(userinfo)
            {
              username = userinfo.unique_name;
            }
            var logData:LogData = {  Type: 'normal',
            Logtext: username +' has logged out of the website',
            UserName: username};
        


            var sessionNow = "";
            this.authenticationService.newUserInfoSession = JSON.parse(localStorage.getItem("UserInfoSession"));
            if(this.authenticationService.newUserInfoSession && this.authenticationService.newUserInfoSession.session)
            {
              sessionNow = this.authenticationService.newUserInfoSession.session;
            }

            this.logDataAPISubscription=this.DataService.logDataAPI(logData).subscribe(data=>{
                  //added on 4/20/2022
                  this.logOutSubscription = this.authenticationService.logout(sessionNow).subscribe(stuff=>{
                    this.DataService.logout(false);
                    //clear storage variables
                    //localStorage.clear();

                    this.router.navigate(['/login']);
                    this.delayProcessing(3000).then(data =>{
                      window.location.reload();
                    });

                  },error=>{
                    this.DataService.logout(false);
                    //clear storage variables
                    //localStorage.clear();

                    this.router.navigate(['/login']);
                    this.delayProcessing(3000).then(data =>{
                      window.location.reload();
                    });
                  });//end of logout

            },error=>{ 

                  //added on 4/20/2022
                  this.logOutSubscription = this.authenticationService.logout(sessionNow).subscribe(stuff=>{
                    this.DataService.logout(false);
                    //clear storage variables
                    //localStorage.clear();

                    this.router.navigate(['/login']);
                    this.delayProcessing(3000).then(data =>{
                      window.location.reload();
                    });
                  },error=>{
                    this.DataService.logout(false);
                    //clear storage variables
                    //localStorage.clear();

                    this.router.navigate(['/login']);
                    this.delayProcessing(3000).then(data =>{
                      window.location.reload();
                    });
                  });//end of logout

            });//

            localStorage.setItem('reloaded','0');

            localStorage.removeItem('menu');
            localStorage.removeItem('userInfo');
            localStorage.removeItem('isLogin');
            localStorage.removeItem('token');
            this.jwtService.destroyToken();
            this.authenticationService.currentUser.next(null);

            localStorage.setItem("UserInfoSessionOld", sessionNow);

              
              this.delayProcessing(1000).then(data =>{

            });

        

      }//end of fx

      async delayProcessing(delayAmount: number) {
        return new Promise(resolve => setTimeout(resolve, delayAmount));
      }//end

      onRefreshPage(){

        let url = window.location.href.replace(window.location.host, '').replace('http://', '');
        this.delayProcessing(1000).then(data =>{
          window.location.reload();
        });
    

      }

      refreshPage() {
        let url = window.location.href.replace(window.location.host, '').replace('http://', '');
        this.delayProcessing(1000).then(data =>{
          window.location.reload();
        });

      }

      //added on 4/20/2022
      ngOnDestroy() { 
        if(this.userSubscription && !this.userSubscription.closed) this.userSubscription.unsubscribe();  
        if(this.logDataAPISubscription && !this.logDataAPISubscription.closed) this.logDataAPISubscription.unsubscribe(); 
        if(this.logOutSubscription && !this.logOutSubscription.closed) this.logOutSubscription.unsubscribe();     
      }//end

      //05/02/2022
      //added on Jan 13, 2022
      setTimeout() //user screen timeout due to inactivity
      {
        //console.log('set timeout begins');
        this.userActivity = setTimeout(() => {
          if (this.login) {
            this.userInactive.next(undefined);
            console.log('user is logged out due to session timeout');
          }
        }, 
        //maximum allowed inactivity before logout is 30 minutes per security policy
        1800000//time in milliseconds = 30 minutes = 30X60X1000 = 1,800,000MS
        //1800000
        );
      }//end

      setSessionRemainingTime()
      {
        var elapsedTimeIntervalRef = setInterval(() => {
                //displayedElapsedTime = timeAndDateHandling.getElapsedTime(startDate);
                //token timeout
                var userinfo = JSON.parse(localStorage.getItem("userInfo"));
                var sessionExp:Date;
                if(userinfo && userinfo.exp)
                {
                  sessionExp = new Date(Number(userinfo.exp)*1000);
                  //console.log('sessionExpiration = ',sessionExp);
                }
                else
                sessionExp = new Date(0);

                var currentDate = new Date();
                //console.log('Now = ',currentDate);

                //time remaining for the user session until timeout
                this.sessionTimeRemainingMinutes = Math.floor((Number(sessionExp.getTime()-currentDate.getTime())/(1000))/60);
                this.sessionTimeRemainingSeconds = Math.floor((Number(sessionExp.getTime()-currentDate.getTime())/(1000))%60);
                if(this.sessionTimeRemainingSeconds<10)
                this.sessionTimeRemainingSecondsVal = '0'+String(this.sessionTimeRemainingSeconds);
                else
                {
                  this.sessionTimeRemainingSecondsVal = String(this.sessionTimeRemainingSeconds);
                }
               

                this.sessionTimeRemainingWholeSeconds = Math.floor(Number(sessionExp.getTime()-currentDate.getTime())/(1000));                

          }, 1000); // 1000 as in 1 second
      }//end
    
      @HostListener('window:mousemove') handleMouseMove() //reset screen timeout when user moves the mouse
      {
        clearTimeout(this.userActivity);
        //console.log('mouse moved - timer reset');
        this.setTimeout();
        this.setSessionRemainingTime();
      }//end
  
      @HostListener('window:keydown') handleKeyDown() //reset screen timeout when user presses keys
      {
        clearTimeout(this.userActivity);
        //console.log('key pressed - timer reset');
        this.setTimeout();
        this.setSessionRemainingTime();
      }//end
  
      @HostListener('window:scroll') handleScroll() //reset screen timeout when user presses keys
      {
        clearTimeout(this.userActivity);
        //console.log('key scroll - timer reset');
        this.setTimeout();
        this.setSessionRemainingTime();
      }//end
  

}
